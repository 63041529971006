import React from "react"
import { slugify } from "../../../utils"
import Button from "../../atoms/Button"
import ExternalLink from "../../atoms/ExternalLink"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import BackgroundMedia from "../BackgroundMedia"
import MediaTextBlock, { MediaTextBlockLayout } from "./MediaTextBlock"
//import * as styles from "./CtaToExternalUrlBlock.module.scss"

export type CtaToExternalUrlBlockProps = Queries.DatoSharedCtaToExternalUrlFragment & {
  layout: MediaTextBlockLayout
  backgroundColor?: HarviaBackgroundColor
}

const CtaToExternalUrlBlock = (props: CtaToExternalUrlBlockProps) => {
  //console.log('Rendering CtaToExternalUrlBlock', props)
  return (
    <MediaTextBlock
      id={slugify(props.title)}
      layout={props.layout}
      contentBackground={props.backgroundColor}
      media={props.image && <BackgroundMedia {...props.image} />}
      content={
        <>
          <h3>{props.title}</h3>
          <p style={{ flexGrow: 1 }}>{props.text}</p>
          <ExternalLink href={props.url}>
            <Button label={props.linkText} tabIndex={-1} />
          </ExternalLink>
        </>
      }
    />
  )
}

export default CtaToExternalUrlBlock
