import { graphql } from "gatsby"
import React from "react"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import CtaToExternalUrlBlock from "../../molecules/tileable-blocks/CtaToExternalUrlBlock"
import CtaToPageBlock from "../../molecules/tileable-blocks/CtaToPageBlock"
import { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
//import * as styles from "./DatoSharedCtaToPageRef.module.scss"

export type DatoCtaRefProps = {
  cta:
    | ({ __typename: "DatoCmsSharedCtaToPage" } & Queries.DatoSharedCtaToPageFragment)
    | ({ __typename: "DatoCmsSharedCtaToExternalUrl" } & Queries.DatoSharedCtaToExternalUrlFragment)

  layout: MediaTextBlockLayout
  textBackground?: HarviaBackgroundColor
}

const DatoCtaRef = (props: Queries.DatoCtaRefFragment) => {
  //console.log("Rendering DatoCtaRef", props)

  if (!props.cta) return null

  const { cta, layout, textBackground } = props
  const bg = (textBackground || "BrandWhite") as HarviaBackgroundColor
  const la = (layout || "Tile") as MediaTextBlockLayout

  const { text, title, linkText, image } = cta

  if (!title || !linkText) {
    return null
  }

  switch (cta.__typename) {
    case "DatoCmsSharedCtaToPage":
      return (
        <CtaToPageBlock
          text={text || undefined}
          title={title}
          linkText={linkText}
          backgroundColor={bg}
          image={image || undefined}
          route={cta.route}
          layout={la}
        />
      )
    case "DatoCmsSharedCtaToExternalUrl":
      return cta.url ? (
        <CtaToExternalUrlBlock
          text={text || undefined}
          title={title}
          linkText={linkText}
          backgroundColor={bg}
          image={image || undefined}
          url={cta.url}
          layout={la}
        />
      ) : null
  }

  //return <CtaToPageBlock {...{ ...rest, ...cta }} />
}

export default DatoCtaRef

export const query = graphql`
  fragment DatoCtaRef on DatoCmsCtaRef {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    cta {
      ... on Node {
        __typename
      }
      ... on DatoCmsSharedCtaToPage {
        ...DatoSharedCtaToPage
      }
      ... on DatoCmsSharedCtaToExternalUrl {
        ...DatoSharedCtaToExternalUrl
      }
    }
    layout
    textBackground
  }
`
